import { Injectable, OnInit } from '@angular/core';
import {
  PageMeta,
  BasePageMetaResolver,
  PageDescriptionResolver,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  PageType,
  TranslationService,
  CmsService,
  PageMetaService,
} from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPageMetaResolver
  extends PageMetaResolver
  implements
    PageTitleResolver,
    PageDescriptionResolver,
    PageRobotsResolver
{

  constructor(
    protected translationService: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver,
    private cmsService:CmsService,
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'LandingPage2Template';
  }

  resolveCanonicalUrl(): Observable<any>{
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }

  resolveTitle(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveTitle();
  }


  resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription();
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }
}
