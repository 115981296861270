export const environment = {
  production: true,
  siteKey: 'myboeingfleet',
  prodSiteUrl: 'https://services.boeing.com',
  baseOCCUrl: 'https://services.boeing.com',
  hybSiteUrl: 'https://services.boeing.com',
  siteUrl: 'https://val-myboeingfleet-vip.bdhm.digitalaviationservices.com',
  adobeLaunchUrl: '//assets.adobedtm.com/launch-EN70835a95f14943879b6cd23b7d6c7977.min.js',
  gtmId: 'GTM-MW7TJKZ',
  //Adobe tagging
  adobeCommonSDKUrl:'https://assets.adobedtm.com/981fe46f491f/bdf6dbd8451c/launch-a114652e2038-staging.min.js',
  // Adobe Analytics Common SDK 
  adobeCommonEnabled:false,
  adobeCommonPages:[],
  version:'',
  OhpCardView: true
};


// get the common library package number
getVersionNumber().then((version) => {
  environment.version = version;
  })

// function to get the common library package number
async function getVersionNumber(): Promise<string> {
  const data = await import('../../package.json');
 return data.dependencies['@bdhm/bgs-common-ui-lib'];
}