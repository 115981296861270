import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { redirectJSON } from 'src/constants/redirects';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
  
    private router: Router
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):any {
    const url = state.url;
    const matchingItem = redirectJSON.find((item) => item['SOURCE_URL'] === url);
    if (matchingItem) {
      const redirect_url = matchingItem['REDIRECT_URL'];
       window.location.href = redirect_url;
    }

  }
  
}
