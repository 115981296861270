import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BgsCommonUiLibModule } from '@bdhm/bgs-common-ui-lib';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { provideConfig } from "@spartacus/core";
import { AepModule } from '@spartacus/tracking/tms/aep';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpartacusModule } from './spartacus/spartacus.module';
import { ReqHeaderIntercepterService } from "../services/req-header-intercepter.service";
import { NavigationEvent } from "@spartacus/storefront";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BgsCommonUiLibModule,
    SpartacusModule,
    BaseTmsModule.forRoot(),
    GtmModule,
    AepModule
  ],
  providers: [
     {
       provide: HTTP_INTERCEPTORS,
       useClass: ReqHeaderIntercepterService,
      multi: true
    },
    {
      provide: 'Environment', useValue: environment
    },
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment.gtmId,
          events: [NavigationEvent],
        }
      },
    } as TmsConfig),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClient(withFetch(), withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
