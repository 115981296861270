import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReqHeaderIntercepterService implements HttpInterceptor{

  constructor(  private router:Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req.clone({
      headers: req.headers.delete('Content-Type').set('Accept','*/*').delete('Access-Control-Request-Headers').delete('Access-Control-Request-Method').delete('X-Anonymous-Consents')
    });
//redirect to not found page if  404  status code coming  from the service call
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {

          switch (error.status) {
            case 401:
              console.log('Unauthorized');
              break;
            case 404:
              // this.router.navigate(['notFound'])
              console.info('404: not found');
              break
            case (0 || 504):
              console.info('Probable CORS error', error);
              break;
            default:
              console.log('Error: ', error);
          }
        }
        throw new HttpErrorResponse(error);
      })
    );
  }
}
