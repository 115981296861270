import { Component, OnInit, Inject, ElementRef, Renderer2, afterNextRender, afterRender, PLATFORM_ID } from '@angular/core';
 import { AdobeTagService } from '@bdhm/bgs-common-ui-lib';
import {CustomPageMetaResolver} from '../services/customPage.resolver'
import { Meta } from '@angular/platform-browser';
import { CmsService, Page } from '@spartacus/core';
import {customPageModel} from './app.model'
import {AppService} from './app.service'
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import packageInfo from '@bdhm/bgs-common-ui-lib/package.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss'
  ]
})
export class AppComponent implements OnInit {
  title = 'myboeingfleet';
  
  constructor(
    private customResolver: CustomPageMetaResolver, private meta:Meta, 
    private CmsService: CmsService, private appService: AppService, 
    private elementRef: ElementRef,private renderer: Renderer2,
    private adobeTagService: AdobeTagService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object

  ) {  

    //listen the anchor tag clicks which as # as value 
    this.startListening();
    if(isPlatformBrowser(this.platformId)){
    // launch the adobe script in head tag of the html
    this.adobeTagService.AdobeLaunchScript();
    // page lavel adobe tagging call
    this.adobeTagService.AdobePageLevelEvent();
    }  
    this.disableConsoleInProduction();
  }

  disableConsoleInProduction(): void { 

    if(packageInfo?.version){
      console.info(`%c👉 Common lib version: %c ${packageInfo.version} 👈`,'color: FireBrick; background: white; font-size: 18px;','color: RoyalBlue; background: white; font-size: 18px; font-weight: bold;');
    }

    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }
  }

  ngOnInit(): void {
   if(isPlatformBrowser(this.platformId)){
    this.CmsService.getCurrentPage().subscribe(data=>{
          this.addMetaTags(data);       
    });

  //resolve the canonical url
    this.customResolver.resolveCanonicalUrl().subscribe(data=>{
      if (data) {
        let link: HTMLLinkElement = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', data);
        document.head.appendChild(link);
      }
      
    })

  //resolve the description
    this.customResolver.resolveDescription().subscribe(data=>{
      if(data && data !== "")
        {
          this.meta.updateTag({property:'og:description', content:data});
          this.meta.updateTag({name:'description', content:data})
          this.meta.updateTag({name:'twitter:description', content:data})
        }
    })

    this.meta.addTag({name:'twitter:card', content: 'summary_large_image'})
   }
  }

  //method tp add the meta tags
  addMetaTags(data: Page) {
    if(data?.robots?.length){
      this.meta.updateTag({name: "robots", content: data?.robots?.join(", ")});
    }
    if(data?.properties){
      this.appService.setPageMetaTags(data?.properties);
    }
    if(data?.title){
      this.meta.updateTag({ property: 'og:title', content: data.title });
      this.meta.updateTag({ name: 'twitter:title', content: data.title });
    }
    if (data?.template?.toLowerCase().includes('article')) {
      this.meta.updateTag({ property: 'og:type', content: 'article' })
    } else {
      this.meta.updateTag({ property: 'og:type', content: 'website' })
    }
  
  }
//function listen the anchor tag clicks which has # as value 
startListening() {
  const nativeElement = this.elementRef.nativeElement;
  nativeElement.addEventListener('click', this.clickHandler, true);
  return () => {
    nativeElement.removeEventListener('click', this.clickHandler, true);
  };
}

// Define the click handler 
clickHandler = (event: { target: HTMLAnchorElement; preventDefault: () => void; }) => {
  if (event.target.nodeName === 'A') {
    const href = (event.target as HTMLAnchorElement).getAttribute('href');
    if (href && href.startsWith('#')) {
      event.preventDefault();
      window.location.href = window.location.pathname + href;
    }
  }
};
}