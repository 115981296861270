export const redirectJSON = [
{"SOURCE_URL":"/20210326_737ng-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/20210326_md-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/20210326_MD-Discounted-List/","REDIRECT_URL":"/promo/commercial-overstock-sale"},
{"SOURCE_URL":"/747-8-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/757-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/767-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/777-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/787-discounted-list","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/about/","REDIRECT_URL":"/about"},
{"SOURCE_URL":"/access-request/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/access-request-2","REDIRECT_URL":"/access-request"},
{"SOURCE_URL":"/access-request-3","REDIRECT_URL":"/access-request"},
{"SOURCE_URL":"/access-request-4","REDIRECT_URL":"/access-request"},
{"SOURCE_URL":"/access-request-5","REDIRECT_URL":"/access-request"},
{"SOURCE_URL":"/access-request-6","REDIRECT_URL":"/access-request"},
{"SOURCE_URL":"/ahm","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/airplane-health-management-ahm"},
{"SOURCE_URL":"/aircraft-modification-parts","REDIRECT_URL":"/leasing/transition-services/aircraft-modification-parts-resources"},
{"SOURCE_URL":"/aircraft-modification-parts/","REDIRECT_URL":"/leasing/transition-services/aircraft-modification-parts-resources"},
{"SOURCE_URL":"/ams","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/asset-management-solution"},
{"SOURCE_URL":"/analytics-solutions","REDIRECT_URL":"/"},
{"SOURCE_URL":"/aviation-business-solutions","REDIRECT_URL":"/aviation-business-solutions-consulting"},
{"SOURCE_URL":"/aviation-business-solutions/aviation-operations-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/aviation-operations-consulting"},
{"SOURCE_URL":"/aviation-business-solutions/it-cybersecurity-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/it-cybersecurity-consulting"},
{"SOURCE_URL":"/aviation-business-solutions/maintenance-engineering-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/maintenance-engineering-consulting"},
{"SOURCE_URL":"/aviation-business-solutions/military-aviation-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/military-aviation-consulting"},
{"SOURCE_URL":"/aviation-maintenance-basics","REDIRECT_URL":"/news/aviation-maintenance-basics"},
{"SOURCE_URL":"/BGS","REDIRECT_URL":"https://services.boeing.com"},
{"SOURCE_URL":"/bgsmedias/bgs-records-management-services.pdf?context=bWFzdGVyfHBkZnwxODgwNjd8YXBwbGljYXRpb24vcGRmfHBkZi9oMjcvaGVhLzg4NTkzMDI3ODkxNTAucGRmfGQ3MDI4YjZhZDhlZjgzMWQwYzRjN2RlOGQ0MGRjMWUyZjFiMDM2ZTQ3OWNhMzE5N2UzMzkwNTZmOGJlNmI1ZWM&attachment=true","REDIRECT_URL":"/bgsmedias/records-management-solution-rms-fact-sheet-2023?context=bWFzdGVyfHJvb3R8MzEzODIzfGFwcGxpY2F0aW9uL3BkZnxoYTYvaDJmLzg4NzkzOTQ4NDg3OTgucGRmfDk1N2I5ODE5Yzk4ODFkZDk5ZjllYTQyZTcxZmMxMjg4MGIzMjA5YzdmMDc5MDM1MzQzZTVmNjQ1OTViZWI4ZGE"},
{"SOURCE_URL":"/boeing-aid","REDIRECT_URL":"/maintenance-engineering/modifications/avionics/aircraft-interface-device-boeing-aid"},
{"SOURCE_URL":"/boeing-aid/","REDIRECT_URL":"/maintenance-engineering/modifications/avionics/aircraft-interface-device-boeing-aid"},
{"SOURCE_URL":"/boeing-digital-direct-article","REDIRECT_URL":"/news/boeing-digital-direct"},
{"SOURCE_URL":"/boeing-digital-direct-article/","REDIRECT_URL":"/news/boeing-digital-direct-not-just-inflight-entertainment"},
{"SOURCE_URL":"/boeing-events","REDIRECT_URL":"/events"},
{"SOURCE_URL":"/boeing-global-services-web-form","REDIRECT_URL":"/subscribe"},
{"SOURCE_URL":"/boeing-landing-gear-exchange-program-lowers-capital-costs","REDIRECT_URL":"/news/boeing-landing-gear-exchange-program-lowers-capital-costs"},
{"SOURCE_URL":"/Boeing-Mobile-Logbook","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/boeing-mobile-logbook"},
{"SOURCE_URL":"/Boeing-Mobile-Logbook/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/boeing-mobile-logbook/?utm_source=bgs&utm_medium=ons&utm_campaign=bgsonsbanr20200225v01","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/boeingservices.com/digitaldirect","REDIRECT_URL":"/maintenance-engineering/modifications/boeing-digital-direct"},
{"SOURCE_URL":"/boeing-services-building-the-future-together-update","REDIRECT_URL":"/"},
{"SOURCE_URL":"/boeing-spectrum","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/boeing-spectrum/","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/business-aviation","REDIRECT_URL":"/about/business-aviation"},
{"SOURCE_URL":"/business-aviation/","REDIRECT_URL":"/about/business-aviation"},
{"SOURCE_URL":"/cabin-safety-training","REDIRECT_URL":"/training-solutions/cabin-safety-training"},
{"SOURCE_URL":"/campuses","REDIRECT_URL":"/training-solutions/campuses"},
{"SOURCE_URL":"/chemical-logistics-and-management-programs","REDIRECT_URL":"/parts/managed-part-and-repair-programs/chemical-logistics-and-management-programs"},
{"SOURCE_URL":"/commercial-aviation","REDIRECT_URL":"/about/commercial-aviation"},
{"SOURCE_URL":"/commercial-aviation/","REDIRECT_URL":"/about/commercial-aviation"},
{"SOURCE_URL":"/consulting","REDIRECT_URL":"/aviation-business-solutions-consulting"},
{"SOURCE_URL":"/consulting/","REDIRECT_URL":"/aviation-business-solutions-consulting"},
{"SOURCE_URL":"/consulting/airline-airport-operations-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/aviation-operations-consulting"},
{"SOURCE_URL":"/consulting/airline-airport-operations-consulting/","REDIRECT_URL":"/aviation-business-solutions-consulting/aviation-operations-consulting"},
{"SOURCE_URL":"/consulting/it-cybersecurity-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/it-cybersecurity-consulting"},
{"SOURCE_URL":"/consulting/it-cybersecurity-consulting/","REDIRECT_URL":"/aviation-business-solutions-consulting/it-cybersecurity-consulting"},
{"SOURCE_URL":"/consulting/maintenance-engineering-consulting","REDIRECT_URL":"/aviation-business-solutions-consulting/maintenance-engineering-consulting"},
{"SOURCE_URL":"/consulting/maintenance-engineering-consulting/","REDIRECT_URL":"/aviation-business-solutions-consulting/maintenance-engineering-consulting"},
{"SOURCE_URL":"/contactUs","REDIRECT_URL":"/helpful-resources"},
{"SOURCE_URL":"/contact-us","REDIRECT_URL":"/helpful-resources"},
{"SOURCE_URL":"/contact-us/","REDIRECT_URL":"/helpful-resources"},
{"SOURCE_URL":"/digitaldirect","REDIRECT_URL":"/passenger-experience/boeing-digital-direct"},
{"SOURCE_URL":"/draft/events/connect-2023-recap","REDIRECT_URL":"/events/connect-2023-recap"},
{"SOURCE_URL":"/efpac","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/engine-fleet-planning-and-costing-efpac"},
{"SOURCE_URL":"/emergent-services","REDIRECT_URL":"/parts/emergent-services"},
{"SOURCE_URL":"/emergent-services/","REDIRECT_URL":"/parts/emergent-services"},
{"SOURCE_URL":"/emissions-reporter","REDIRECT_URL":"https://services.boeing.com/flight-operations/flight-efficiency-sustainability/emissions-reporter"},
{"SOURCE_URL":"/engine-fleet-planning-and-costing-efpac","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/engine-fleet-planning-and-costing-efpac"},
{"SOURCE_URL":"/engine-fleet-planning-and-costing-efpac/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/engine-fleet-planning-and-costing-efpac"},
{"SOURCE_URL":"/events/","REDIRECT_URL":"/events"},
{"SOURCE_URL":"/events/webinars/fda-boeing-maintenance-solutions-insight-accelerator","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/events/webinars/fda-dashboard-editor-embracing-data","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/events/webinars/fda-four-ways-to-unlock-insights-about-trends-in-your-flight-operations","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/events/webinars/fda-insights-and-opportunities-to-improve-fleet-reliability","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/events/webinars/fda-using-data-to-drive-fuel-and-emissions-programs","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/exchange-programs","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/exchange-programs/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/faa-dispatch","REDIRECT_URL":"https://shop.jeppesen.com/faa-dispatch"},
{"SOURCE_URL":"/favicon.ico","REDIRECT_URL":"/_ui/responsive/theme-alpha/images/favicon.boeing.ico"},
{"SOURCE_URL":"/fda","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/fleetlink","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/fleet-link"},
{"SOURCE_URL":"/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/","REDIRECT_URL":"/flight-operations"},
{"SOURCE_URL":"/flight-operations/airspace-optimization/","REDIRECT_URL":"/flight-operations/airspace-optimization"},
{"SOURCE_URL":"/flight-operations/crew-solutions","REDIRECT_URL":"/flight-operations/crew-management-system"},
{"SOURCE_URL":"/flight-operations/crew-solutions/","REDIRECT_URL":"/flight-operations/flight-operations/crew-management-system"},
{"SOURCE_URL":"/flight-operations/data-solutions/","REDIRECT_URL":"/flight-operations/data-solutions"},
{"SOURCE_URL":"/flight-operations/flight-and-fuel-optimization","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/flight-operations/flight-and-fuel-optimization/wind-updates","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/?utm_source=boe&utm_medium=ons&utm_campaign=boeonslink20210218v01","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/?utm_source=boe&utm_medium=ons&utm_campaign=boeonslink20210218v02","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/advanced-safety-analytics","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/boeing-insight-accelerator","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/boeing-insight-accelerator"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/boeing-insight-accelerator/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/emissions-reporter","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability/emissions-reporter"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring/?utm_campaign=8002F050B091012131E15251B16","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring/?utm_campaign=80035050B091C1A1F111425191B16 &li_fat_id=50f659df-0ad3-4a80-851d-460bd9bfe219","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring/?utm_campaign=80043050B091019131E16251B16","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/flight-operational-quality-assurance-flight-data-monitoring/?utm_campaign=8005B05070A130D13281320191B16","REDIRECT_URL":"//maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/fuel-dashboard","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics/insight-accelerator","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics-webinar-series","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-data-analytics-webinar-series/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/flight-operations/flight-planning-and-dispatch/","REDIRECT_URL":"/flight-operations/flight-planning-and-dispatch"},
{"SOURCE_URL":"/flight-operations/navigation-solutions/","REDIRECT_URL":"/flight-operations/navigation-solutions"},
{"SOURCE_URL":"/flight-operations/navigation-solutions/onboard-performance-tool","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability/onboard-performance-tool"},
{"SOURCE_URL":"/flight-operations/navigation-solutions/onboard-performance-tool/","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability/onboard-performance-tool"},
{"SOURCE_URL":"/flight-operations/network-operations-management/","REDIRECT_URL":"/flight-operations/network-operations-management"},
{"SOURCE_URL":"/flight-operations/wind-updates","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/flight-operations/wind-updates/","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability"},
{"SOURCE_URL":"/foqa","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/general-aviation","REDIRECT_URL":"/about/general-aviation"},
{"SOURCE_URL":"/general-aviation/","REDIRECT_URL":"/about/general-aviation"},
{"SOURCE_URL":"/government-services","REDIRECT_URL":"/about/government-services"},
{"SOURCE_URL":"/government-services/","REDIRECT_URL":"/about/government-services"},
{"SOURCE_URL":"/helpful-resources","REDIRECT_URL":"/helpful-links"},
{"SOURCE_URL":"/industries","REDIRECT_URL":"/"},
{"SOURCE_URL":"/insight-accelerator","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/IOC","REDIRECT_URL":"https://smilingpassengers.com"},
{"SOURCE_URL":"/korean-air-adopts-boeing-insight-accelerator","REDIRECT_URL":"/news/korean-air-adopts-boeing-insight-accelerator"},
{"SOURCE_URL":"/landing-gear-exchange","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs/landing-gear-exchange-program"},
{"SOURCE_URL":"/landing-gear-exchange-program","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/landing-gear-exchange-program/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/latest-news","REDIRECT_URL":"/news"},
{"SOURCE_URL":"/latest-news/a-digital-crystal-ball-boeings-analytics-team-predicts-maintenance-issues-before-they-happen","REDIRECT_URL":"/news/a-digital-crystal-ball-boeings-analytics-team-predicts-maintenance-issues-before-they-happen"},
{"SOURCE_URL":"/latest-news/ancillary-revenue-generation-key-to-airlines-emerging-from-covid-19-challenges","REDIRECT_URL":"/news/ancillary-revenue-generation-key-to-airlines-emerging-from-covid-19-challenges"},
{"SOURCE_URL":"/latest-news/ancillary-revenue-generation-key-to-airlines-emerging-from-covid-19-challenges/","REDIRECT_URL":"/news/ancillary-revenue-generation-key-to-airlines-emerging-from-covid-19-challenges"},
{"SOURCE_URL":"/latest-news/bbam-adds-up-to-12-737-800-boeing-converted-freighters","REDIRECT_URL":"/news/bbam-adds-up-to-12-737-800-boeing-converted-freighters"},
{"SOURCE_URL":"/latest-news/bbam-orders-12-additional-737-800-boeing-converted-freighters","REDIRECT_URL":"/news/bbam-orders-12-additional-737-800-boeing-converted-freighters"},
{"SOURCE_URL":"/latest-news/boeing-digital-direct","REDIRECT_URL":"/news/boeing-digital-direct"},
{"SOURCE_URL":"/latest-news/boeing-digital-direct/","REDIRECT_URL":"/news/boeing-digital-direct"},
{"SOURCE_URL":"/latest-news/boeing-digital-services-to-optimize-planning-and-operations-for-frontier-airlines-through-new-10-year-agreement","REDIRECT_URL":"/news/boeing-digital-services-to-optimize-planning-and-operations-for-frontier-airlines-through-new-10-year-agreement"},
{"SOURCE_URL":"/latest-news/boeing-digital-services-to-optimize-planning-and-operations-for-frontier-airlines-through-new-10-year-agreement/","REDIRECT_URL":"/news/boeing-digital-services-to-optimize-planning-and-operations-for-frontier-airlines-through-new-10-year-agreement"},
{"SOURCE_URL":"/latest-news/boeing-supply-chain-services-agreements-strengthen-etihad-airways-operational-excellence","REDIRECT_URL":"/news/boeing-supply-chain-services-agreements-strengthen-etihad-airways-operational-excellence"},
{"SOURCE_URL":"/latest-news/demand-for-aviation-personnel","REDIRECT_URL":"/news/demand-for-aviation-personnel"},
{"SOURCE_URL":"/latest-news/digital-solutions-and-analytics-tools-help-airlines-navigate-covid-19-challenges","REDIRECT_URL":"/news/digital-solutions-and-analytics-tools-help-airlines-navigate-covid-19-challenges"},
{"SOURCE_URL":"/latest-news/digital-solutions-and-analytics-tools-help-airlines-navigate-covid-19-challenges/","REDIRECT_URL":"/news/digital-solutions-and-analytics-tools-help-airlines-navigate-covid-19-challenges"},
{"SOURCE_URL":"/latest-news/effective-manpower-planning-during-unprecedented-uncertainty","REDIRECT_URL":"/news/effective-manpower-planning-during-unprecedented-uncertainty"},
{"SOURCE_URL":"/latest-news/effective-manpower-planning-during-unprecedented-uncertainty/","REDIRECT_URL":"/news/effective-manpower-planning-during-unprecedented-uncertainty"},
{"SOURCE_URL":"/latest-news/fuel-dashboard-savings-calculation-methodology","REDIRECT_URL":"/news/fuel-dashboard-savings-calculation-methodology"},
{"SOURCE_URL":"/latest-news/fuel-dashboard-savings-calculation-methodology/","REDIRECT_URL":"/news/fuel-dashboard-savings-calculation-methodology"},
{"SOURCE_URL":"/latest-news/how-can-electronic-logbooks-help-streamline-operations","REDIRECT_URL":"/news/how-can-electronic-logbooks-help-streamline-operations"},
{"SOURCE_URL":"/latest-news/how-can-electronic-logbooks-help-streamline-operations/","REDIRECT_URL":"/news"},
{"SOURCE_URL":"/latest-news/how-data-analytics-fuels-smarter-airplane-maintenance","REDIRECT_URL":"/news/how-data-analytics-fuels-smarter-airplane-maintenance"},
{"SOURCE_URL":"/latest-news/integrated-planning-is-the-new-normal","REDIRECT_URL":"/news/integrated-planning-is-the-new-normal"},
{"SOURCE_URL":"/latest-news/kidventure-stem-sets-the-stage-for-the-next-generation-of-aviation-enthusiasts","REDIRECT_URL":"/news/kidventure-stem-sets-the-stage-for-the-next-generation-of-aviation-enthusiasts"},
{"SOURCE_URL":"/latest-news/managing-the-transitional-usm-market","REDIRECT_URL":"/news/managing-the-transitional-usm-market"},
{"SOURCE_URL":"/latest-news/page/2","REDIRECT_URL":"/news"},
{"SOURCE_URL":"/latest-news/recovering-flight-service-levels-boeing-flight-data-analytics-services-continue-to-improve-airline-efficiency-through-enhanced-decision-making","REDIRECT_URL":"/news/recovering-flight-service-levels-boeing-flight-data-analytics-services-continue-to-improve-airline-efficiency-through-enhanced-decision-making"},
{"SOURCE_URL":"/latest-news/recovering-flight-service-levels-boeing-flight-data-analytics-services-continue-to-improve-airline-efficiency-through-enhanced-decision-making/","REDIRECT_URL":"/news/recovering-flight-service-levels-boeing-flight-data-analytics-services-continue-to-improve-airline-efficiency-through-enhanced-decision-making"},
{"SOURCE_URL":"/latest-news/smart-efb-technology-debuts-in-jeppesen-flitedeck-pro-with-release-of-version-4-4","REDIRECT_URL":"/news/smart-efb-technology-debuts-in-jeppesen-flitedeck-pro-with-release-of-version-4-4"},
{"SOURCE_URL":"/latest-news/smart-efb-technology-debuts-in-jeppesen-flitedeck-pro-with-release-of-version-4-4/","REDIRECT_URL":"/news/smart-efb-technology-debuts-in-jeppesen-flitedeck-pro-with-release-of-version-4-4"},
{"SOURCE_URL":"/latest-news/supporting-lessors-with-aircraft-transition-services","REDIRECT_URL":"/news/supporting-lessors-with-aircraft-transition-services"},
{"SOURCE_URL":"/latest-news/supporting-lessors-with-aircraft-transition-services/","REDIRECT_URL":"/news/supporting-lessors-with-aircraft-transition-services"},
{"SOURCE_URL":"/latest-news/the-benefit-of-fleet-agnostic-solutions-in-maintenance-operations","REDIRECT_URL":"/news/the-benefit-of-fleet-agnostic-solutions-in-maintenance-operations"},
{"SOURCE_URL":"/latest-news/turkish-technic-expands-inventory-with-boeing-parts-agreement","REDIRECT_URL":"/news/turkish-technic-expands-inventory-with-boeing-parts-agreement"},
{"SOURCE_URL":"/latest-news/united-aviate-academy-selects-boeing-to-provide-suite-of-early-career-training-solutions","REDIRECT_URL":"/news/united-aviate-academy-selects-boeing-to-provide-suite-of-early-career-training-solutions"},
{"SOURCE_URL":"/latest-news/united-aviate-academy-selects-boeing-to-provide-suite-of-early-career-training-solutions/","REDIRECT_URL":"/news/united-aviate-academy-selects-boeing-to-provide-suite-of-early-career-training-solutions"},
{"SOURCE_URL":"/latest-news/used-serviceable-materials-and-the-future","REDIRECT_URL":"/news/used-serviceable-materials-and-the-future"},
{"SOURCE_URL":"/latest-news/used-serviceable-materials-and-the-future/","REDIRECT_URL":"/news/used-serviceable-materials-and-the-future"},
{"SOURCE_URL":"/latest-news/virtual-modification-home-edition","REDIRECT_URL":"/news/virtual-modification-home-edition"},
{"SOURCE_URL":"/lease-transition-camo","REDIRECT_URL":"/leasing/transition-services/camo"},
{"SOURCE_URL":"/lease-transition-services","REDIRECT_URL":"/leasing"},
{"SOURCE_URL":"/lease-transition-services/","REDIRECT_URL":"/leasing/transition-services"},
{"SOURCE_URL":"/lease-transition-services/?utm_source=bgs&utm_medium=ons&utm_campaign=bgsonsbanr20200728v04","REDIRECT_URL":"/leasing/transition-services"},
{"SOURCE_URL":"/lease-transition-services/aircraft-modification-parts","REDIRECT_URL":"/leasing/transition-services/aircraft-modification-parts-resources"},
{"SOURCE_URL":"/lease-transition-services/engineering-design-and-modifications","REDIRECT_URL":"/"},
{"SOURCE_URL":"/lease-transition-services/lease-transition-camo","REDIRECT_URL":"/leasing/transition-services/continuing-airworthiness-management-organization-camo"},
{"SOURCE_URL":"/lease-transition-services/lease-transition-camo/","REDIRECT_URL":"/leasing/transition-services/continuing-airworthiness-management-organization-camo"},
{"SOURCE_URL":"/lease-transition-services/mro-and-airplane-storage-services","REDIRECT_URL":"/leasing/transition-services/mro-and-airplane-storage-services"},
{"SOURCE_URL":"/lease-transition-services/mro-and-airplane-storage-services/","REDIRECT_URL":"/leasing/transition-services/mro-and-airplane-storage-services"},
{"SOURCE_URL":"/lease-transition-services/technical-records-and-asset-managed-services","REDIRECT_URL":"/leasing/transition-services/technical-records-and-asset-managed-services/"},
{"SOURCE_URL":"/lease-transition-services/technical-records-and-asset-managed-services/","REDIRECT_URL":"/leasing/transition-services/technical-records-and-asset-managed-services"},
{"SOURCE_URL":"/leasing","REDIRECT_URL":"/maintenance-engineering"},
{"SOURCE_URL":"/leasing/transition-services","REDIRECT_URL":"/maintenance-engineering/transition-services"},
{"SOURCE_URL":"/leasing/transition-services/aircraft-modification-parts-resources","REDIRECT_URL":"/maintenance-engineering/transition-services/aircraft-modification-parts-resources"},
{"SOURCE_URL":"/leasing/transition-services/continuing-airworthiness-management-organization-camo","REDIRECT_URL":"/maintenance-engineering/transition-services/continuing-airworthiness-management-organization-camo"},
{"SOURCE_URL":"/leasing/transition-services/mro-and-airplane-storage-services","REDIRECT_URL":"/maintenance-engineering/transition-services/mro-and-airplane-storage-services"},
{"SOURCE_URL":"/leasing/transition-services/technical-records-and-asset-managed-services","REDIRECT_URL":"/maintenance-engineering/transition-services/technical-records-and-asset-managed-services"},
{"SOURCE_URL":"/leasing/transition-services/technical-records-and-asset-managed-services/","REDIRECT_URL":"/leasing/transition-services/technical-records-and-asset-managed-services"},
{"SOURCE_URL":"/licensed-manuals","REDIRECT_URL":"/training-solutions/flight-training/licensed-manuals"},
{"SOURCE_URL":"/london-gatwick-campus","REDIRECT_URL":"/training-solutions/campuses/london-gatwick-campus"},
{"SOURCE_URL":"/maintenance-engineering/airplane-health-management-ahm","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/airplane-health-management-ahm"},
{"SOURCE_URL":"/maintenance-engineering/airplane-health-management-ahm/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/airplane-health-management-ahm"},
{"SOURCE_URL":"/maintenance-engineering/avionics-upgrades","REDIRECT_URL":"/maintenance-engineering/modifications/avionics"},
{"SOURCE_URL":"/maintenance-engineering/avionics-upgrades/","REDIRECT_URL":"/maintenance-engineering/modifications/avionics"},
{"SOURCE_URL":"/maintenance-engineering/fleet-engineering-services","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/boeing-insight-accelerator"},
{"SOURCE_URL":"/maintenance-engineering/fleet-reliability-services","REDIRECT_URL":"https://services.boeing.com/maintenance-engineering/technical-engineering-services"},
{"SOURCE_URL":"/maintenance-engineering/london-gatwick-hangar-and-mro/","REDIRECT_URL":"/maintenance-engineering/london-gatwick-hangar-and-mro"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-execution","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-execution/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-execution/aog-services","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/aog-services"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-execution/aog-services/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/aog-services"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-optimization/airplane-health-","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/airplane-health-management-ahm"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-optimization/boeing-insight-accelerator","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/insight-accelerator"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-optimization/boeing-mobile-logbook","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-optimization/maintenance-turn-time","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization"},
{"SOURCE_URL":"/maintenance-engineering/maintenance-optimization/optimized-maintenance-program/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/optimized-maintenance-program"},
{"SOURCE_URL":"/maintenance-engineering/modifications/","REDIRECT_URL":"/maintenance-engineering/modifications"},
{"SOURCE_URL":"/maintenance-engineering/modifications/avionics/aircraft-interface-device-boeing-aid-Aerospace-Tech-Week","REDIRECT_URL":"/maintenance-engineering/modifications/avionics/aircraft-interface-device-boeing-aid"},
{"SOURCE_URL":"/maintenance-engineering/modifications/avionics-upgrades","REDIRECT_URL":"/maintenance-engineering/modifications/avionics"},
{"SOURCE_URL":"/maintenance-engineering/modifications/avionics-upgrades/","REDIRECT_URL":"/maintenance-engineering/modifications/avionics"},
{"SOURCE_URL":"/maintenance-engineering/modifications/boeing-digital-direct","REDIRECT_URL":"/passenger-experience/boeing-digital-direct"},
{"SOURCE_URL":"/maintenance-engineering/modifications/boeing-digital-direct/","REDIRECT_URL":"/passenger-experience/boeing-digital-direct"},
{"SOURCE_URL":"/maintenance-engineering/modifications/boeing-digital-direct/?utm_source=bgs&utm_medium=dis&utm_campaign=bgsdisbanr20201119v02","REDIRECT_URL":"/passenger-experience/boeing-digital-direct"},
{"SOURCE_URL":"/maintenance-engineering/modifications/boeing-digital-direct/?utm_source=bgs&utm_medium=soc&utm_campaign=bgssoclink20201119v02","REDIRECT_URL":"/passenger-experience/boeing-digital-direct"},
{"SOURCE_URL":"/maintenance-engineering/modifications/boeing-digital-direct-2","REDIRECT_URL":"/maintenance-engineering/modifications/boeing-digital-direct"},
{"SOURCE_URL":"/maintenance-engineering/modifications/interior-modifications","REDIRECT_URL":"/maintenance-engineering/modifications/interiors"},
{"SOURCE_URL":"/maintenance-engineering/modifications/interior-modifications/","REDIRECT_URL":"/maintenance-engineering/modifications/interiors"},
{"SOURCE_URL":"/maintenance-engineering/modifications/passenger-connectivity","REDIRECT_URL":"/"},
{"SOURCE_URL":"/maintenance-engineering/modifications/performance-upgrades","REDIRECT_URL":"/maintenance-engineering/modifications/performance"},
{"SOURCE_URL":"/maintenance-engineering/modifications/performance-upgrades/","REDIRECT_URL":"/maintenance-engineering/modifications/performance"},
{"SOURCE_URL":"/maintenance-engineering/optimized-maintenance-program","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/optimized-maintenance-program"},
{"SOURCE_URL":"/maintenance-engineering/tailored-solutions","REDIRECT_URL":"/maintenance-engineering"},
{"SOURCE_URL":"/maintenance-engineering/technical-content-management","REDIRECT_URL":"/maintenance-engineering/technical-data-services"},
{"SOURCE_URL":"/maintenance-engineering/technical-content-management/Boeing-Mobile-Logbook","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/boeing-mobile-logbook"},
{"SOURCE_URL":"/maintenance-engineering/technical-content-management/maintenance-performance-toolbox","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/maintenance-performance-toolbox"},
{"SOURCE_URL":"/maintenance-engineering/technical-content-management/maintenance-performance-toolbox/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/maintenance-performance-toolbox"},
{"SOURCE_URL":"/maintenance-engineering/technical-data-services/","REDIRECT_URL":"/maintenance-engineering/technical-data-services"},
{"SOURCE_URL":"/maintenance-training","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/maintenance-training/","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/maintenance-training-services","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/maintenance-training-services/","REDIRECT_URL":"/training-solutions/maintenance-training/maintenance-training-services"},
{"SOURCE_URL":"/maintenance-turn-time","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/maintenance-turn-time"},
{"SOURCE_URL":"/maintenance-turn-time/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/maintenance-turn-time"},
{"SOURCE_URL":"/managed-part-and-repair-programs","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/managed-part-and-repair-programs/","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/material-rebate-program","REDIRECT_URL":"/parts"},
{"SOURCE_URL":"/mobile-logbook","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/boeing-mobile-logbook"},
{"SOURCE_URL":"/mro-and-airplane-storage-services","REDIRECT_URL":"/transition-services/mro-and-airplane-storage-services"},
{"SOURCE_URL":"/news/how-can-electronic-logbooks-help-streamline-operations","REDIRECT_URL":"/news"},
{"SOURCE_URL":"/news/norse-atlantic-airways-selects-boeing-for-digital-maintenance-solutions","REDIRECT_URL":"/news"},
{"SOURCE_URL":"/part-kits","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits"},
{"SOURCE_URL":"/part-kits/","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits"},
{"SOURCE_URL":"/part-packages","REDIRECT_URL":"/parts/parts-kits-and-packages/part-packages"},
{"SOURCE_URL":"/parts/","REDIRECT_URL":"/parts"},
{"SOURCE_URL":"/parts/consumable-expendable-services","REDIRECT_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management"},
{"SOURCE_URL":"/parts/consumable-expendable-services/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management"},
{"SOURCE_URL":"/parts/emergent-services-2","REDIRECT_URL":"/parts/emergent-services"},
{"SOURCE_URL":"/parts/emergent-services-2/","REDIRECT_URL":"/parts/emergent-services"},
{"SOURCE_URL":"/parts/exchange-programs","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/parts/exchange-programs/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/parts/fleet-material-solutions","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/parts/fleet-material-solutions/","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/aerostructures-exchange-program","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs/aerostructures-exchange-program"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/consumable-expendable-services","REDIRECT_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management/"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/consumable-expendable-services/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/consumable-expendable-services/paint-mixing","REDIRECT_URL":"/parts/repair-services/paint-mixing"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/exchange-programs/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management/","REDIRECT_URL":"/parts/managed-part-and-repair-programs/integrated-materials-management"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/paint-mixing","REDIRECT_URL":"/parts/repair-services/paint-mixing"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/paint-mixing/","REDIRECT_URL":"/parts/repair-services/paint-mixing"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/repair-management-programs","REDIRECT_URL":"/parts/repair-services"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/repair-management-programs/","REDIRECT_URL":"/parts/repair-services"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/repair-management-programs/battery-services","REDIRECT_URL":"/parts/repair-services/battery-services"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/repair-management-programs/hose-shop-services","REDIRECT_URL":"/parts/repair-services/hose-shop-services"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/repair-management-programs/wheel-and-brake-repair","REDIRECT_URL":"/parts/repair-services/wheel-and-brake-repair"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/rotables-and-exchange","REDIRECT_URL":"/parts/parts-kits-and-packages/rotables-and-exchange"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/rotables-and-exchange/","REDIRECT_URL":"/parts/parts-kits-and-packages/rotables-and-exchange"},
{"SOURCE_URL":"/parts/managed-part-and-repair-programs/transactional-lease-and-exchange","REDIRECT_URL":"/parts/managed-part-and-repair-programs/exchange-programs/transactional-lease-and-exchange"},
{"SOURCE_URL":"/parts/oem-certified-parts","REDIRECT_URL":"/parts/parts-kits-and-packages"},
{"SOURCE_URL":"/parts/oem-certified-parts/","REDIRECT_URL":"/parts/parts-kits-and-packages"},
{"SOURCE_URL":"/parts/onboard-performance-tool","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability/onboard-performance-tool"},
{"SOURCE_URL":"/parts/onboard-performance-tool/","REDIRECT_URL":"/flight-operations/flight-efficiency-sustainability/onboard-performance-tool"},
{"SOURCE_URL":"/parts/parts-kits-and-packages/","REDIRECT_URL":"/parts/parts-kits-and-packages"},
{"SOURCE_URL":"/parts/parts-kits-and-packages/part-kits/","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits"},
{"SOURCE_URL":"/parts/parts-kits-and-packages/part-kits/quick-engine-change-kits-qec/","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits/quick-engine-change-kits-qec"},
{"SOURCE_URL":"/parts/parts-kits-and-packages/part-packages/tailored-parts-package/","REDIRECT_URL":"/parts/parts-kits-and-packages/part-packages/tailored-parts-package"},
{"SOURCE_URL":"/parts/parts-services","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/parts/parts-services/","REDIRECT_URL":"/parts/managed-part-and-repair-programs"},
{"SOURCE_URL":"/parts/shop-for-aircraft-parts-online","REDIRECT_URL":"https://shop.boeing.com"},
{"SOURCE_URL":"/parts/shop-for-aircraft-parts-online/","REDIRECT_URL":"https://shop.boeing.com/"},
{"SOURCE_URL":"/parts/supply-chain-solutions","REDIRECT_URL":"/parts"},
{"SOURCE_URL":"/parts/tailored-parts-package","REDIRECT_URL":"/parts/parts-kits-and-packages/part-packages/tailored-parts-package"},
{"SOURCE_URL":"/parts/used-serviceable-materials/","REDIRECT_URL":"/parts/parts-kits-and-packages/used-serviceable-materials"},
{"SOURCE_URL":"/parts/used-serviceable-materials/boeing-platform-usm","REDIRECT_URL":"/parts/parts-kits-and-packages/used-serviceable-materials"},
{"SOURCE_URL":"/parts/used-serviceable-materials/fixed-wing-airframe-usm","REDIRECT_URL":"/parts/parts-kits-and-packages/used-serviceable-materials"},
{"SOURCE_URL":"/parts/used-serviceable-materials/helicopter-usm","REDIRECT_URL":"/parts/parts-kits-and-packages/used-serviceable-materials"},
{"SOURCE_URL":"/parts-kits-and-packages","REDIRECT_URL":"/parts/parts-kits-and-packages"},
{"SOURCE_URL":"/parts-kits-and-packages/","REDIRECT_URL":"/parts/parts-kits-and-packages"},
{"SOURCE_URL":"/parts-kits-and-packages/part-packages/tailored-parts-package","REDIRECT_URL":"/parts/parts-kits-and-packages/part-packages/tailored-parts-package"},
{"SOURCE_URL":"/parts-provisioning-data-and-t-files","REDIRECT_URL":"/maintenance-engineering/technical-data-solutions/parts-provisioning-data-and-t-files"},
{"SOURCE_URL":"/passenger-experience","REDIRECT_URL":"/maintenance-engineering/modifications/interiors"},
{"SOURCE_URL":"/passenger-experience/boeing-digital-direct","REDIRECT_URL":"/maintenance-engineering/modifications/interiors/boeing-digital-direct"},
{"SOURCE_URL":"/qrepair-management-programs","REDIRECT_URL":"/parts/managed-part-and-repair-programs/repair-management-programs"},
{"SOURCE_URL":"/quick-engine-change-kits-qec","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits/quick-engine-change-kits-qec"},
{"SOURCE_URL":"/quick-engine-change-kits-qec/","REDIRECT_URL":"/parts/parts-kits-and-packages/part-kits/quick-engine-change-kits-qec"},
{"SOURCE_URL":"/rms","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/records-management-solution"},
{"SOURCE_URL":"/s1000d-services","REDIRECT_URL":"/maintenance-engineering/technical-data-services/s1000d-services"},
{"SOURCE_URL":"/seattle-campus","REDIRECT_URL":"/training-solutions/campuses/seattle-campus"},
{"SOURCE_URL":"/seattle-campus/","REDIRECT_URL":"/training-solutions/campuses/seattle-campus"},
{"SOURCE_URL":"/service-bulletin-advisor","REDIRECT_URL":"/service-bulletin-center"},
{"SOURCE_URL":"/service-bulletin-advisor/","REDIRECT_URL":"/service-bulletin-center"},
{"SOURCE_URL":"/service-bulletin-center/","REDIRECT_URL":"/service-bulletin-center"},
{"SOURCE_URL":"/shortened-type-rating-courses","REDIRECT_URL":"/training-solutions/shortened-type-rating-courses"},
{"SOURCE_URL":"/sign-up","REDIRECT_URL":"/boeing-global-services-web-form/"},
{"SOURCE_URL":"/sign-up/","REDIRECT_URL":"/subscribe"},
{"SOURCE_URL":"/sign-up/?utm_source=bgs&utm_medium=ons&utm_campaign=bgsonsbanr20210401v01","REDIRECT_URL":"/subscribe"},
{"SOURCE_URL":"/software-distribution-manager-sdm","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/software-distribution-manager-sdm"},
{"SOURCE_URL":"/software-distribution-manager-sdm/","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/software-distribution-manager-sdm"},
{"SOURCE_URL":"/solutions","REDIRECT_URL":"/"},
{"SOURCE_URL":"/solutions/engineering-services","REDIRECT_URL":"/maintenance-engineering/modifications"},
{"SOURCE_URL":"/solutions/engineering-services/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services"},
{"SOURCE_URL":"/solutions/software-for-technical-publications","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/software-for-technical-publications/","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/software-for-technical-publications/inmedius-ata-publishing-suite","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/software-for-technical-publications/inmedius-ata-publishing-suite/","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/software-for-technical-publications/inmedius-spectrum","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/software-for-technical-publications/inmedius-spectrum/","REDIRECT_URL":"/maintenance-engineering/technical-data-services/technical-data-software-spectrum"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/technical-documentation/interactive-electronic-technical-manualspublications-ietmsietps","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/technical-documentation/interactive-electronic-technical-manualspublications-ietmsietps/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/technical-documentation/parts-provisioning-data","REDIRECT_URL":"/maintenance-engineering/technical-data-servicess/parts-provisioning-data-and-t-files"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/technical-documentation/s1000d-services","REDIRECT_URL":"/maintenance-engineering/technical-data-services/s1000d-services"},
{"SOURCE_URL":"/solutions/technical-documentation-training-solutions/training-solutions","REDIRECT_URL":"/training-solutions"},
{"SOURCE_URL":"/support","REDIRECT_URL":"/contact-us"},
{"SOURCE_URL":"/support/","REDIRECT_URL":"/helpful-resources"},
{"SOURCE_URL":"/technical-authoring-services","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/technical-authoring-services/","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/technical-authoring-services"},
{"SOURCE_URL":"/toolbox","REDIRECT_URL":"/maintenance-engineering/maintenance-optimization/maintenance-performance-toolbox"},
{"SOURCE_URL":"/tool-leasing-services","REDIRECT_URL":"/maintenance-engineering/technical-engineering-services/tool-leasing-services"},
{"SOURCE_URL":"/training","REDIRECT_URL":"/training-solutions"},
{"SOURCE_URL":"/training/","REDIRECT_URL":"/training-solutions"},
{"SOURCE_URL":"/training/boeing-simulator-packages-hardware-and-data","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-packages-hardware-and-data"},
{"SOURCE_URL":"/training/cabin-safety-training","REDIRECT_URL":"/training-solutions/cabin-safety-training"},
{"SOURCE_URL":"/training/cabin-safety-training/","REDIRECT_URL":"/training-solutions/cabin-safety-training"},
{"SOURCE_URL":"/training/campuses","REDIRECT_URL":"/training-solutions/campuses"},
{"SOURCE_URL":"/training/campuses/","REDIRECT_URL":"/training-solutions/campuses"},
{"SOURCE_URL":"/training/differences-courses","REDIRECT_URL":"/training-solutions/flight-training/differences-courses"},
{"SOURCE_URL":"/training/differences-courses/","REDIRECT_URL":"/training-solutions/flight-training/differences-courses"},
{"SOURCE_URL":"/training/digital-services","REDIRECT_URL":"/training-solutions/simulator-solutions/digital-services"},
{"SOURCE_URL":"/training/digital-services/","REDIRECT_URL":"//training-solutions/simulator-solutions/digital-services"},
{"SOURCE_URL":"/training/flight-training","REDIRECT_URL":"/training-solutions/flight-training"},
{"SOURCE_URL":"/training/flight-training/","REDIRECT_URL":"/training-solutions/flight-training"},
{"SOURCE_URL":"/training/london-gatwick-campus","REDIRECT_URL":"/training-solutions/campuses/london-gatwick-campus/"},
{"SOURCE_URL":"/training/london-gatwick-campus/","REDIRECT_URL":"/training-solutions/campuses/london-gatwick-campus"},
{"SOURCE_URL":"/training/maintenance-training","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/training/maintenance-training/","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/training/maintenance-training/maintenance-training-services","REDIRECT_URL":"/training-solutions/maintenance-training/maintenance-training-services/"},
{"SOURCE_URL":"/training/maintenance-training/maintenance-training-services/","REDIRECT_URL":"/training-solutions/maintenance-training/maintenance-training-services"},
{"SOURCE_URL":"/training/maintenance-training-services","REDIRECT_URL":"/training-solutions/maintenance-training"},
{"SOURCE_URL":"/training/maintenance-training-services/","REDIRECT_URL":"/training-solutions/maintenance-training/maintenance-training-services"},
{"SOURCE_URL":"/training/miami-campus","REDIRECT_URL":"/training-solutions/campuses/miami-campus/"},
{"SOURCE_URL":"/training/miami-campus/","REDIRECT_URL":"/training-solutions/campuses/miami-campus"},
{"SOURCE_URL":"/training/moscow-campus","REDIRECT_URL":"/training-solutions/campuses/moscow-campus/"},
{"SOURCE_URL":"/training/pilot-resourcing","REDIRECT_URL":"/training-solutions/pilot-resourcing"},
{"SOURCE_URL":"/training/pilot-resourcing/","REDIRECT_URL":"/training-solutions/pilot-resourcing"},
{"SOURCE_URL":"/training/pilot-resourcing-2","REDIRECT_URL":"/training-solutions/pilot-resourcing"},
{"SOURCE_URL":"/training/recurrent-courses","REDIRECT_URL":"/training-solutions/flight-training/recurrent-courses/"},
{"SOURCE_URL":"/training/recurrent-courses/","REDIRECT_URL":"/training-solutions/flight-training/recurrent-courses"},
{"SOURCE_URL":"/training/shanghai-campus","REDIRECT_URL":"/training-solutions/campuses/shanghai-campus/"},
{"SOURCE_URL":"/training/shanghai-campus/","REDIRECT_URL":"/training-solutions/campuses/shanghai-campus"},
{"SOURCE_URL":"/training/shortened-type-rating-courses","REDIRECT_URL":"/training-solutions/flight-training/shortened-type-rating-courses"},
{"SOURCE_URL":"/training/shortened-type-rating-courses/","REDIRECT_URL":"/training-solutions/flight-training/shortened-type-rating-courses"},
{"SOURCE_URL":"/training/simulator-services","REDIRECT_URL":"/training-solutions/simulator-solutions"},
{"SOURCE_URL":"/training/simulator-services/","REDIRECT_URL":"/training-solutions/simulator-solutions"},
{"SOURCE_URL":"/training/simulator-services/digital-services","REDIRECT_URL":"/training-solutions/simulator-solutions/digital-services"},
{"SOURCE_URL":"/training/simulator-solutions","REDIRECT_URL":"/training-solutions/simulator-solutions"},
{"SOURCE_URL":"/training/simulator-solutions/","REDIRECT_URL":"/training-solutions/simulator-solutions"},
{"SOURCE_URL":"/training/simulator-solutions/boeing-simulator-packages-hardware-and-data","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-packages-hardware-and-data"},
{"SOURCE_URL":"/training/simulator-solutions/boeing-simulator-packages-hardware-and-data/","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-packages-hardware-and-data"},
{"SOURCE_URL":"/training/simulator-solutions/digital-services","REDIRECT_URL":"/training-solutions/simulator-solutions/digital-services/"},
{"SOURCE_URL":"/training/simulator-solutions/digital-services/","REDIRECT_URL":"/training-solutions/simulator-solutions/digital-services"},
{"SOURCE_URL":"/training/simulator-solutions/simulator-updates-and-modifications","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-updates-and-modifications/"},
{"SOURCE_URL":"/training/simulator-solutions/simulator-updates-and-modifications/","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-updates-and-modifications"},
{"SOURCE_URL":"/training/simulator-updates-and-modifications","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-updates-and-modifications"},
{"SOURCE_URL":"/training/simulator-updates-and-modifications/","REDIRECT_URL":"/training-solutions/simulator-solutions/simulator-updates-and-modifications"},
{"SOURCE_URL":"/training/singapore-campus","REDIRECT_URL":"/training-solutions/campuses/singapore-campus"},
{"SOURCE_URL":"/training/singapore-campus/","REDIRECT_URL":"/training-solutions/campuses/singapore-campus"},
{"SOURCE_URL":"/training/training-ecommerce","REDIRECT_URL":"/training-solutions/shop-online"},
{"SOURCE_URL":"/training/training-ecommerce/","REDIRECT_URL":"/training-solutions/shop-online"},
{"SOURCE_URL":"/training/training-ecommerce/my-boeing-training","REDIRECT_URL":"/training-solutions/shop-online/my-boeing-training/"},
{"SOURCE_URL":"/training/training-ecommerce/my-boeing-training/","REDIRECT_URL":"/training-solutions/shop-online/my-boeing-training"},
{"SOURCE_URL":"/training/training-ecommerce/simulator-marketplace","REDIRECT_URL":"/training-solutions/shop-online/simulator-marketplace/"},
{"SOURCE_URL":"/training/training-ecommerce/simulator-marketplace/","REDIRECT_URL":"/training-solutions/shop-online/simulator-marketplace"},
{"SOURCE_URL":"/training/type-rating-courses","REDIRECT_URL":"/training-solutions/flight-training/type-rating-courses/"},
{"SOURCE_URL":"/training/type-rating-courses/","REDIRECT_URL":"/training-solutions/flight-training/type-rating-courses"},
{"SOURCE_URL":"/training-and-professional-services-2","REDIRECT_URL":"/training-solutions"},
{"SOURCE_URL":"/training-solutions/","REDIRECT_URL":"/training-solutions"},
{"SOURCE_URL":"/training-solutions/campuses/london-gatwick-campus/","REDIRECT_URL":"/training-solutions/campuses/london-gatwick-campus"},
{"SOURCE_URL":"/training-solutions/campuses/miami-campus/","REDIRECT_URL":"/training-solutions/campuses/miami-campus"},
{"SOURCE_URL":"/training-solutions/campuses/shanghai-campus/","REDIRECT_URL":"/training-solutions/campuses/shanghai-campus"},
{"SOURCE_URL":"/training-solutions/early-career/bls","REDIRECT_URL":"/training-solutions/early-career/boeing-learning-solutions"},
{"SOURCE_URL":"/training-solutions/flight-training/recurrent-courses/","REDIRECT_URL":"/training-solutions/flight-training/recurrent-courses"},
{"SOURCE_URL":"/training-solutions/flight-training/type-rating-courses/","REDIRECT_URL":"/training-solutions/flight-training/type-rating-courses"},
{"SOURCE_URL":"/training-solutions/maintenance-training/maintenance-training-services/","REDIRECT_URL":"/training-solutions/maintenance-training/maintenance-training-services"},
{"SOURCE_URL":"/training-solutions/shop-online/my-boeing-training/","REDIRECT_URL":"/training-solutions/shop-online/my-boeing-training"},
{"SOURCE_URL":"/training-solutions/shop-online/simulator-marketplace/","REDIRECT_URL":"/training-solutions/shop-online/simulator-marketplace"},
{"SOURCE_URL":"/type-rating-courses","REDIRECT_URL":"/training-solutions/flight-training/type-rating-courses"},
{"SOURCE_URL":"/wp-content/uploads/2021/05/boeing-distribution-services-discount-part-list.xlsx","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/aviall-relocation-and-name-change-letter.pdf","REDIRECT_URL":"https://www.boeingdistribution.com/"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/aviall-singapore-","REDIRECT_URL":"https://www.boeingdistribution.com/"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/aviall-singapore-name-change.pdf","REDIRECT_URL":"https://www.boeingdistribution.com/"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/baga-bdi-discounted-list_06222021.xlsx","REDIRECT_URL":"https://shop.boeing.com/promo/commercial_overstock_sale"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/make-an-offer_062321.xlsx","REDIRECT_URL":"https://www.boeingdistribution.com/"},
{"SOURCE_URL":"/wp-content/uploads/2021/06/qxzpywxslv","REDIRECT_URL":"https://www.boeingdistribution.com/"},
{"SOURCE_URL":"/wp-content/uploads/2021/09/new-boeing-replacement-parts-list.xlsx","REDIRECT_URL":"https://www.boeingdistribution.com/"}
]
