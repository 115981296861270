import { NgModule } from '@angular/core';
import { InitialNavigation, RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from '@spartacus/storefront';
import { redirectJSON } from 'src/constants/redirects';
import { RouteGuard } from 'src/services/route.guard';

export const routes: Routes = redirectJSON.map(data=>{
  return {
    path : removeFirstSlash(data['SOURCE_URL']),
    component:PageLayoutComponent,
    canActivate: [RouteGuard], // Apply the global guard
  }
})

// function to remove the first slash 
function removeFirstSlash(url){
if(url.startsWith('/')){
  return url.substring(1);
}
return url;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    initialNavigation: 'enabled' as InitialNavigation })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
